<template>
  <main id="cart">
    <bar-breadcrumbs :data="breadcrumbs" />

    <client-only>
      <section class="bar__categories">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4">
              <header-h3
                :content="$t('components.cart.data')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="0"
              />
              <div v-if="cartQty === 0">
                <div
                  v-html="$t('components.cart.empty')"
                  class="my-4 bg-orange text-white px-4 py-2"
                />
              </div>
              <div v-else>
                <div
                  v-if="message"
                  v-html="errorText"
                  class="my-4 bg-orange text-white px-4 py-2">
                </div>
                <form @submit.prevent @submit="submitOrder">
                  <p
                    v-if="!loggedIn"
                    v-html="
                      `${$t('components.cart.account')} <a href='${localePath(
                        'account'
                      )}'>${$t('components.account.login')}</a>`
                    "
                    class="mt-6 text-center"
                  />
                  <div class="flex flex-wrap mt-6 -mx-4">
                    <div
                      :class="
                        `w-full tablet:w-2/3 px-4 ${
                          loggedIn ? 'pointer-events-none' : ''
                        }`
                      "
                    >
                      <header-h5
                        :content="$t('components.cart.headers.personal_data')"
                        casing="uppercase"
                        mt="0"
                        mb="4"
                      />
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="
                            `${$t('components.account.form.company_name')}`
                          "
                          for-id="form_field_company_name"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="company_name"
                          :value="company_name"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.company_name')} *`
                          "
                          name="company_name"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="
                            `${$t('components.account.form.first_name')} *`
                          "
                          for-id="form_field_first_name"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="first_name"
                          :value="first_name"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.first_name')} *`
                          "
                          name="first_name"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="
                            `${$t('components.account.form.last_name')} *`
                          "
                          for-id="form_field_last_name"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="last_name"
                          :value="last_name"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.last_name')} *`
                          "
                          name="last_name"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="`${$t('components.account.form.street')} *`"
                          for-id="form_field_street"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="street"
                          :value="street"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.street')} *`
                          "
                          name="street"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="
                            `${$t('components.account.form.house_number')} *`
                          "
                          for-id="form_field_house_number"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="house_number"
                          :value="house_number"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.house_number')} *`
                          "
                          name="house_number"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="
                            `${$t('components.account.form.postal_code')} *`
                          "
                          for-id="form_field_postal_code"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="postal_code"
                          :value="postal_code"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.postal_code')} *`
                          "
                          name="postal_code"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="`${$t('components.account.form.city')} *`"
                          for-id="form_field_city"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="city"
                          :value="city"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.city')} *`
                          "
                          name="city"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="`${$t('components.account.form.country')} *`"
                          for-id="form_field_country"
                          extra-class="block mb-2 hidden"
                        />
                        <div class="form_item">
                          <form-input
                            v-model="country"
                            :value="country"
                            :required="true"
                            :placeholder="
                              `${$t('components.account.form.country')} *`
                            "
                            name="country"
                          />
                          <!--                        <select-->
                          <!--                          id="form_field_country"-->
                          <!--                          v-model="country"-->
                          <!--                          @change="recalculate"-->
                          <!--                          class="form_input"-->
                          <!--                          required-->
                          <!--                        >-->
                          <!--                          <option-->
                          <!--                            v-html="-->
                          <!--                              `${$t('components.account.form.country')} *`-->
                          <!--                            "-->
                          <!--                            value=""-->
                          <!--                          />-->
                          <!--                          <option-->
                          <!--                            v-for="country in countries"-->
                          <!--                            :value="country.id"-->
                          <!--                            v-html="country.name"-->
                          <!--                          />-->
                          <!--                        </select>-->
                        </div>
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="`${$t('components.account.form.phone')}`"
                          for-id="form_field_phone"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="phone"
                          :value="phone"
                          :required="false"
                          :placeholder="
                            `${$t('components.account.form.phone')}`
                          "
                          type="tel"
                          name="phone"
                        />
                      </div>
                      <div class="form-group w-full mb-4">
                        <form-label
                          :label="`${$t('components.account.form.email')} *`"
                          for-id="form_field_email"
                          extra-class="block mb-2 hidden"
                        />
                        <form-input
                          v-model="email"
                          :value="email"
                          :required="true"
                          :placeholder="
                            `${$t('components.account.form.email')} *`
                          "
                          type="email"
                          name="email"
                        />
                      </div>
                    </div>
                    <div class="w-full tablet:w-1/3 px-4">
                      <header-h5
                        :content="$t('components.cart.headers.order_data')"
                        casing="uppercase"
                        mt="0"
                        mb="4"
                      />
                      <table-main
                        :table="{
                          thead: [
                            [
                              $t('components.cart.headers.product_name'),
                              $t('components.cart.headers.price')
                            ]
                          ],
                          tbody: cartItems,
                          tfoot: [
                            [
                              $t('components.cart.headers.sub_total'),
                              getPriceFormatted(sub_total)
                            ]
                          ]
                        }"
                        extra-class="table--sm"
                      />
                      <div class="mt-4">
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="
                              `${$t('components.account.form.order_ref')}`
                            "
                            for-id="form_field_order_ref"
                            extra-class="block mb-2 hidden"
                          />
                          <form-input
                            v-model="order_ref"
                            :required="false"
                            :placeholder="
                              `${$t('components.account.form.order_ref')}`
                            "
                            name="order_ref"
                          />
                        </div>
                        <div class="form-group w-full mb-4">
                          <form-label
                            :label="`${$t('components.account.form.remarks')}`"
                            for-id="form_field_remarks"
                            extra-class="block mb-2 hidden"
                          />
                          <form-textarea
                            v-model="remarks"
                            :required="false"
                            :placeholder="
                              `${$t('components.account.form.remarks')}`
                            "
                            name="remarks"
                          />
                        </div>
                      </div>
                      <div class="mt-4">
                        <button-primary
                          :text="$t('form.send')"
                          type="submit-form"
                          extra-class="block"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </client-only>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs';
import portal from '../../service/portal';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Cart',
  components: { BarBreadcrumbs },
  head() {
    const title = 'Cart - Air Conditioning Parts - AP Air LTD.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' }
      ]
    }
  },
  filters: {
    formatPrice(val, isTax = false) {
      if (val === 0 && !isTax) {
        return 'P.O.A.'
      } else {
        return val
          .toFixed(2)
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    }
  },
  data() {
    const rawCartItems = this.$store.state.cartItems;
    const cartItems = [];
    let subtotal = 0.0;
    const transport = 0.0;
    let currentCurrency = this.getCurrency();

    for (let i = 0; i < rawCartItems.length; i++) {
      const cartItem = [];
      cartItem.push(rawCartItems[i].quantity + 'x ' + rawCartItems[i].product.product.name);
      cartItem.push(this.getPriceFormatted(rawCartItems[i].price, rawCartItems[i].currency));
      subtotal += parseFloat(rawCartItems[i].price) * rawCartItems[i].quantity;

      if (rawCartItems[i].currency !== currentCurrency) {
          console.log('item currency different that gloabl currency');
        }

      cartItems.push(cartItem)
    }

    return {
      locale: this.$i18n.locale,
      cartQty: this.$store.state.cartQty,
      rawCartItems,
      cartItems,
      sub_total: subtotal,
      transport,
      countries: [],
      loggedIn: this.$auth.loggedIn,
      company_name: this.$auth.loggedIn ? this.$auth.user.company : '',
      first_name: this.$auth.loggedIn ? this.$auth.user.firstname : '',
      last_name: this.$auth.loggedIn ? this.$auth.user.lastname : '',
      street: this.$auth.loggedIn ? this.$auth.user.street : '',
      house_number: this.$auth.loggedIn ? this.$auth.user.number : '',
      postal_code: this.$auth.loggedIn ? this.$auth.user.postal_code : '',
      city: this.$auth.loggedIn ? this.$auth.user.city : '',
      country: this.$auth.loggedIn ? this.$auth.user.country : '',
      phone: this.$auth.loggedIn ? this.$auth.user.phonenumber : '',
      email: this.$auth.loggedIn ? this.$auth.user.email : '',
      tax: 0,
      grand_total: 0,
      currency: currentCurrency,
      remarks: '',
      order_ref: '',
      message: false,
      errorText: '',
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.cart'),
          url: 'cart'
        },
        {
          name: this.$t('components.cart.data'),
          url: 'cart-data'
        }
      ]
    }
  },
  created() {
    const cartHasChanged = false
    for (let i = 0; i < this.rawCartItems.length; i++) {
      const stockParams = {}
      stockParams.culture = this.$store.state.language
      stockParams.partid = this.rawCartItems[i].partid

      /* let [stockRow] = await Promise.all([
        parts.post(`stock_row_get`, stockParams)
      ])
      stockRow = stockRow.data.row
      if (stockRow.quantity === 0) {
        this.$store.commit('REMOVE_CART_ITEM', stockRow)
        cartHasChanged = true
      } */
    }
    if (cartHasChanged) {
      this.$forceUpdate()
    }
  },
  methods: {
    submitOrder() {
      const body = {}
      if (this.$auth.loggedIn) {
        body.user_id = this.$auth.user.user_id
        body.order_type = 'order'
      } else {
        body.company = this.company_name
        body.firstname = this.first_name
        body.lastname = this.last_name
        body.street = this.street
        body.number = this.house_number
        body.postal_code = this.postal_code
        body.city = this.city
        body.country = this.country
        body.phonenumber = this.phone
        body.email = this.email
        body.order_type = 'enquiry'
      }
      body.reference = this.order_ref
      body.note = this.remarks
      body.order_rows = []
      body.lang = this.$i18n.locale

      if (body.lang === 'ie') {
        body.lang = 'en';
      }

      for (let i = 0; i < this.rawCartItems.length; i++) {
        const row = {}
        row.product_id = this.rawCartItems[i].product.id
        row.quantity = this.rawCartItems[i].quantity
        row.price_per_piece = this.rawCartItems[i].price
        row.currency = this.rawCartItems[i].currency
        body.order_rows.push(row)
      }

      portal
        .post('add-order', body, {
          'Content-Type': 'application/json'
        })
        .then((data) => {
          dataLayer.push({'event': 'enquiry_send_checkout'})

          this.$store.dispatch('setCartStatus', true)
          window.localStorage.removeItem('cartItems')
          window.location.href = this.localePath('cart-thankyou')
        })
        .catch((err) => {
          console.log(err);

          let errorTextNew = [];
          errorTextNew.push(this.$t('components.cart.failed'));

          if (err.response && err.response.data && err.response.data.errors) {
            let keys = Object.keys(err.response.data.errors);

            for (let i = 0; i < keys.length; i++) {
              errorTextNew.push(err.response.data.errors[keys[i]]);
            }
          }

          errorTextNew = errorTextNew.join('<br>');
          this.errorText = errorTextNew;
          this.message = true;
        })
    },
    getCurrency () {
      const priceList = this.$store.state.priceList;
      return CommonUtils.getCurrencyForPriceList(priceList);
    },
    getPriceFormatted (price, currencCurrency = null) {
      let currency = currencCurrency || this.currency;
      if (!price) {
        return 'P.O.A.';
      }

      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency
      }).format(price);
    }
  }
}
</script>
